import Configs from '@/configs'

export const WORKING_HOURS = [
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Monday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Tuesday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Wednesday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Thursday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Friday',
    opens: '09:00',
    closes: '20:00'
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Saturday',
    opens: '10:00',
    closes: '18:00'
  }
]

export const SOCIAL_LINKS = [
  'https://www.youtube.com/channel/UCmdLuXlI90e-7CJBj3epZZg',
  'https://www.facebook.com/shipandstorage',
  'https://www.linkedin.com/company/shipandstorage'
  // 'https://www.instagram.com/shipandstorage/',
]

export const BASE_URL = Configs.appURL || ''
export const APP_NAME = Configs.APP_NAME || ''

export const GEO_LOCATION = {
  '@type': 'GeoCoordinates',
  latitude: 40.7591622,
  longitude: -74.0516313
}

export const ADDRESSES = [
  {
    '@type': 'PostalAddress',
    streetAddress: '228 East 45th Street',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10017',
    addressCountry: 'US'
  }
]

const SERVED_AREAS = ['US']

export const CONTACT_POINTS = [
  {
    '@type': 'ContactPoint',
    telephone: '+1-888-670-4887',
    contactOption: 'http://schema.org/TollFree',
    areaServed: SERVED_AREAS[0],
    contactType: 'customer service',
    availableLanguage: {
      '@type': 'Language',
      name: 'English'
    }
  }
]
