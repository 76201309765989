import { OG_IMAGES } from '@/v2/constants/images/meta'
import { BASE_URL } from '@/v2/constants/structured-data'
import { StructuredDataType } from '@/v2/types/meta'

export const generateFAQStructuredData = (data: StructuredDataType) => {
  const imagesSize = data.imageUrls?.length || 0

  const formattedData = data.faq?.map(question => ({
    '@type': 'Question',
    name: `<h3>${question.question}</h3>`,
    image:
      imagesSize > 0 && data.imageUrls
        ? data.imageUrls[Math.floor(Math.random() * imagesSize)]
        : BASE_URL.concat(OG_IMAGES.DEFAULT),
    acceptedAnswer: {
      '@type': 'Answer',
      text: question.answer
    }
  }))

  const structuredData = {
    // '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: formattedData
  }

  return structuredData
}
