import { APP_NAME } from '@/v2/constants/structured-data'
import { capitalizeFirstLetter, substringUntil } from '@/v2/helpers/formatters/string-formatters'
import { StructuredDataType } from '@/v2/types/meta'

export const generateBreadCrumbsStructuredData = (data: StructuredDataType) => {
  // console.log(data.url)
  const url = new URL(data.url)
  const breadcrumbs = url.pathname.split('/').filter(Boolean)

  breadcrumbs.unshift(url.origin)

  // console.log(breadcrumbs)

  const formattedData = breadcrumbs?.map((breadcrumb, index) => {
    const name = breadcrumb === url.origin ? APP_NAME : capitalizeFirstLetter(breadcrumb.replaceAll('-', ' '))
    const breadcrumbUrl = substringUntil(data.url, breadcrumb)

    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': breadcrumbUrl,
        name
      }
    }
  })

  const structuredData = {
    // '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: formattedData
  }

  return structuredData
}
