import { StructuredDataType } from '@/v2/types/meta'
import { generateBreadCrumbsStructuredData } from './bread-crumbs-structured-data-generator'
// import { generateDefaultStructuredData } from './default-structured-data-generator'
import { generateFAQStructuredData } from './faq-structured-data-generator'

type typeValues = 'default' | 'faq'

export const generateStructuredData = (data: StructuredDataType) => {
  const structuredDataObjects = []

  // structuredDataObjects.push(generateDefaultStructuredData(data))
  structuredDataObjects.push(generateBreadCrumbsStructuredData(data))

  const type = extractStructuredDataType(data)

  if (type === 'faq') {
    structuredDataObjects.push(generateFAQStructuredData(data))
  }

  if (structuredDataObjects.length === 0) {
    throw new Error('No structured data to generate')
  }

  if (structuredDataObjects.length === 1) {
    return { '@context': 'https://schema.org', ...structuredDataObjects[0] }
  }

  return {
    '@context': 'https://schema.org',
    '@graph': structuredDataObjects
  }
}

export const extractStructuredDataType = (data: StructuredDataType): typeValues => {
  if (data.faq && data.faq.length > 0) {
    return 'faq'
  }

  return 'default'
}
