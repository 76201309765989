type optionsValues = 'DEFAULT' | 'BASE_URL'

export const META_IMAGES: Record<optionsValues, string> = {
  DEFAULT: '/images/v2/meta/default_meta_image.jpg',
  BASE_URL: '/images/v2/meta'
}

type ogValues = 'DEFAULT' | 'BASE_URL'

export const OG_IMAGES: Record<ogValues, string> = {
  DEFAULT: '/images/v2/meta/default_og_image.jpg',
  BASE_URL: '/images/v2/meta/og'
}
